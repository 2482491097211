@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  font-size: 15.5px;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.capitalize {
  text-transform: capitalize;
}

select {
  background-color: white;
}

.Toastify__toast {
  font-size: 0.95rem;
  line-height: 1.7;
  padding: 10px;
}

/* ====================== react-voice-visualizer ===================== */
.voice-visualizer__btn-center:not(.voice-visualizer__btn-center-pause),
.voice-visualizer__btn-left-microphone {
  background-color: #4f46e5 !important;
  background-image: url('/src/images/microphone_white.svg');
  background-position: center; /* Center the background image */
  background-repeat: no-repeat; /* Prevent the image from repeating */
  border-color: #c7d2fe;
}

.voice-visualizer__btn-center:not(.voice-visualizer__btn-center-pause) {
  width: 75px !important;
  height: 75px !important;
  padding: 20px !important;
  background-size: 45% !important;
  border-width: 5px !important;
}

.voice-visualizer__btn-center:not(.voice-visualizer__btn-center-pause):hover {
  background-image: url('/src/images/microphone_indigo.svg');
  background-color: #e0e7ff !important;
  border-color: #b5c3ff !important;
}

.voice-visualizer__btn-center:not(.voice-visualizer__btn-center-pause) img,
.voice-visualizer__btn-left-microphone img {
  opacity: 0 !important;
}

.voice-visualizer__btn-left,
.voice-visualizer__btn-center-pause {
  background-color: #4f46e5 !important;
}

.voice-visualizer__btn-center:hover,
.voice-visualizer__btn-left-microphone:hover,
.voice-visualizer__btn-left:hover,
.voice-visualizer__btn-center-pause:hover {
  background-color: #6366f1 !important;
}

.voice-visualizer__control-buttons {
  min-height: 30px !important;
  font-size: 12px !important;
  font-weight: 500 !important;
  min-width: auto !important;
  background-color: #eef2ff !important;
  color: #3730a3 !important;
}
.voice-visualizer__control-buttons:hover {
  background-color: #6366f1 !important;
  color: white !important;
}

/* ===================== end react-voice-visualizer =================== */

.react-multi-email.focused {
  border-color: #4f46e5;
}
